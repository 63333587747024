<template>
  <div class="fitment-nav">
    <van-nav-bar
      title="导航"
      left-arrow
      @click-left="$router.back(-1)"
      fixed
      placeholder
      right-text="完成"
      @click-right="complete"
    />

    <div class="nav-list">
      <div v-for="(item, index) in list" :key="index" class="nav-item">
        <div
          style="
            margin-left: 20px;
            margin-right: 20px;
            display: flex;
            justify-content: space-between;
          "
        >
          <div>导航{{ index + 1 }}</div>
          <!-- 删除按钮 -->
          <div v-if="index > 4">
            <van-button
              size="mini"
              style="box-shadow: 1px 1px 5px #e0e0e0; width: 56px"
              @click="deleteItem(index)"
              >删除</van-button
            >
          </div>
        </div>
        <div class="uploader">
          <!-- 图片上传 -->
          <van-uploader
            v-model="item.imageUrl"
            upload-icon="plus"
            :max-count="1"
            :after-read="afterRead(item)"
            :before-delete="beforeDelete(item)"
          />
        </div>
        <!-- 导航名称输入框 -->
        <van-field
          v-model="item.navigationName"
          label="导航名称"
          placeholder="请输入"
          input-align="right"
        />
        <!-- 导航连接选择 -->
        <van-cell
          title="导航链接"
          is-link
          :value="item.name ? item.name : '请选择'"
          @click="actionShow(index)"
        />
      </div>
    </div>

    <!-- 添加导航按钮 -->
    <div style="padding: 10px" v-if="type == 2">
      <van-button
        type="primary"
        block
        color="linear-gradient(to left, #FF7200, #FF3C00)"
        @click="addNav"
        >添加导航</van-button
      >
    </div>

    <!-- 动作面板 -->
    <van-action-sheet
      v-model="show"
      :actions="actions"
      @select="onSelect"
      cancel-text="取消"
      close-on-click-action
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { upload } from "@/api/upload";
export default {
  name: "fitmentNav",
  data() {
    return {
      index: null,
      type: null,
      list: [],
      show: false,
      actions: [{ name: "商品链接" }, { name: "类目链接" }],
      chosenIndex: null,
    };
  },
  created() {
    this.index = this.$route.query.index;
    this.type = this.$route.query.type;

    this.list = this.fitment.editList;

    if (this.fitment.chosenValue) {
      this.list[this.fitment.chosenIndex].filedId = this.fitment.chosenValue.id;
      this.list[this.fitment.chosenIndex].name = this.fitment.chosenValue.name;
      this.list[this.fitment.chosenIndex].navigationType =
        this.fitment.chosenType === "goods" ? "1" : "2";
      this.$store.commit("chooseLink", {
        type: null,
        index: null,
        value: null,
      });
    }
  },
  computed: {
    ...mapState(["fitment"]),
  },
  methods: {
    // 文件上传
    afterRead(item) {
      return (file) => {
        item.imageUrl[0].status = "uploading";
        item.imageUrl[0].message = "上传中";

        let formData = new window.FormData();
        formData.append("file", file.file);
        formData.append("fileType", "image/png");
        formData.append("dir", "material");

        upload(formData)
          .then((res) => {
            console.log(res);
            item.picUrl = res.data.data;
            item.imageUrl[0].status = "";
            item.imageUrl[0].message = "";
          })
          .catch(() => {
            item.imageUrl[0].status = "failed";
            item.imageUrl[0].message = "上传失败";
          });
      };
    },
    // 删除图片
    beforeDelete(item) {
      return () => {
        item.picUrl = "";
        return true;
      };
    },
    // 删除导航
    deleteItem(index) {
      this.list.splice(index, 1);
    },
    // 动作面板显示
    actionShow(index) {
      console.log(index);
      this.chosenIndex = index;
      this.show = true;
    },
    // 动作面板选择
    onSelect(item) {
      this.show = false;
      // this.$store.commit("setListValue", {
      //   index: this.index,
      //   value: this.list,
      // });
      if (item.name === "商品链接") {
        this.$router.push({
          path: "fitmentGoodsCase",
          query: { index: this.chosenIndex },
        });
      } else {
        this.$router.push({
          path: "fitmentClassification",
          query: { index: this.chosenIndex },
        });
      }
    },
    // 添加导航
    addNav() {
      this.list.push({
        imageUrl: [],
        picUrl: "",
        filedId: null,
        name: "",
        navigationName: "",
        navigationType: "",
      });
    },
    // 右上角完成
    complete() {
      let flag = true;
      for (let index = 0; index < this.list.length; index++) {
        console.log(this.list[index]);
        if (!this.list[index].picUrl) {
          this.$toast("请上传图片" + (index + 1));
          flag = false;
          break;
        }
        if (!this.list[index].navigationName) {
          this.$toast("请输入名称" + (index + 1));
          flag = false;
          break;
        }
        if (!this.list[index].filedId) {
          this.$toast("请选择链接" + (index + 1));
          flag = false;
          break;
        }
      }
      if (flag) {
        this.$store.commit("setListValue", {
          index: this.index,
          value: this.list,
        });
        this.$router.back();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.fitment-nav {
  background-color: #f6f6f6;
  .nav-list {
    .nav-item {
      margin-bottom: 10px;
      padding-top: 10px;
      background-color: #fff;
      .uploader {
        display: flex;
        justify-content: center;
        .van-uploader {
          /deep/ .van-uploader__upload {
            border: 1px solid #dcdee0;
            background-color: #fff;
          }
        }
      }
      /deep/ .van-cell__value,
      .van-icon-arrow {
        color: #ccc;
      }
      /deep/ .van-cell__value {
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>